import { createStyles, Button, getStylesRef } from '@mantine/core';
import { Colors, HtmlTooltip } from '@whylabs/observatory-lib';
import { WhyLabsButton, WhyLabsText } from 'components/design-system';
import { useSearchHandler } from 'hooks/usePageLinkHandler';
import { VIEW_TYPE } from 'types/navTags';
import { useEffect, useState } from 'react';
import { IconLayoutGrid, IconTable } from '@tabler/icons';
import { LayoutType, asLayoutTypeOrDefault } from '../layoutHelpers';

const useWidgetStyles = createStyles({
  labelBase: {
    fontFamily: 'Asap',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '14px',
    marginRight: 8,
    lineHeight: '1.5',
  },
  labelOnDark: {
    color: Colors.white,
  },
  labelOnLight: {
    color: Colors.brandSecondary900,
  },
  layoutSwitch: {
    paddingTop: '10px',
    display: 'flex',
    width: 'max-content',
    flexDirection: 'column',
    gap: 5,
    height: '100%',
    justifyContent: 'center',
  },
  layoutSwitchLightBackground: {
    borderLeft: `1px solid ${Colors.brandSecondary200}`,
    backgroundColor: Colors.white,
    padding: '0 20px',
    height: '100%',
  },
  layoutIconButtonBase: {
    minWidth: 0,
    padding: 0,
    borderRadius: 4,
  },
  layoutIconButtonOnDark: {
    color: Colors.brandSecondary800,
    '&:hover': {
      backgroundColor: Colors.secondaryLight1000,
      color: Colors.white,
    },
  },
  layoutIconButtonOnLight: {
    color: Colors.brandSecondary600,
    '&:hover': {
      backgroundColor: Colors.contrastTableRow,
      color: Colors.brandSecondary800,
    },
  },
  layoutIconButtonActive: {
    color: Colors.brandPrimary200,
    '&:hover': {
      color: Colors.brandPrimary100,
    },
  },
  layoutIconButtonActiveOnLight: {
    color: Colors.chartPrimary,
    backgroundColor: Colors.brandPrimary100,
    border: `1px solid ${Colors.chartPrimary}`,
    '&:hover': {
      backgroundColor: Colors.brandPrimary100,
      color: Colors.brandPrimary800,
    },
  },
  controlButton: {
    padding: '8px !important',
    border: `1px solid ${Colors.mantineLightGray} !important`,
    '&[data-opened-control="false"]:hover': {
      backgroundColor: Colors.brandSecondary200,
      [`& .${getStylesRef('icon')}`]: {
        stroke: Colors.brandPrimary700,
      },
    },
    '&[data-active-control="true"]': {
      backgroundColor: `${Colors.chartPrimary} !important`,
      border: `1px solid ${Colors.chartPrimary} !important`,
      [`& .${getStylesRef('icon')}`]: {
        stroke: `${Colors.white} !important`,
      },
    },
  },
  buttonIcon: {
    ref: getStylesRef('icon'),
    color: Colors.brandSecondary700,
  },
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
});

interface LayoutToggleWidgetProps {
  useLightStyles?: boolean;
}

export const LayoutToggleWidget: React.FC<LayoutToggleWidgetProps> = ({ useLightStyles = false }) => {
  const { classes: styles, cx } = useWidgetStyles();
  const { getSimpleValue, setSimpleValue } = useSearchHandler();

  const urlLayoutType = asLayoutTypeOrDefault(getSimpleValue(VIEW_TYPE));
  const [layoutType, setLayoutType] = useState<LayoutType>(urlLayoutType);

  useEffect(() => {
    if (layoutType === urlLayoutType) {
      return;
    }
    setSimpleValue(VIEW_TYPE, layoutType);
  }, [layoutType, urlLayoutType, setSimpleValue]);

  return (
    <div className={styles.root}>
      <div className={cx(styles.layoutSwitch, useLightStyles && styles.layoutSwitchLightBackground)}>
        <WhyLabsText className={cx(styles.labelBase, useLightStyles ? styles.labelOnLight : styles.labelOnDark)}>
          Layout <HtmlTooltip tooltipContent="Switch between dashboard and table layout" />
        </WhyLabsText>
        <Button.Group>
          <WhyLabsButton
            className={styles.controlButton}
            onClick={() => {
              setLayoutType('card');
            }}
            data-active-control={layoutType === 'card'}
            variant="outline"
            color="gray"
            aria-label="Card layout"
          >
            <IconLayoutGrid className={styles.buttonIcon} size={18} stroke={3} />
          </WhyLabsButton>
          <WhyLabsButton
            className={styles.controlButton}
            onClick={() => {
              setLayoutType('table');
            }}
            data-active-control={layoutType === 'table'}
            variant="outline"
            color="gray"
            aria-label="Table layout"
          >
            <IconTable className={styles.buttonIcon} size={18} stroke={3} />
          </WhyLabsButton>
        </Button.Group>
      </div>
    </div>
  );
};
