import { useResizeObserver } from '@mantine/hooks';
import { WhyLabsText } from 'components/design-system';
import { FeaturePanelHighchartsBoxPlot } from 'components/visualizations/box-plots/FeaturePanelHighchartsBoxPlot';
import { BoxPlotData } from 'components/visualizations/box-plots/types';
import { HistogramDomain } from 'components/visualizations/inline-histogram/histogramUtils';
import { UnifiedHistogramWithMetadata } from 'components/visualizations/OverlaidHistograms/types';
import FeaturePanelHistogramStack from './FeaturePanelHistogramStack';
import { ProfilesHistogramTableController } from './ProfilesHistogramTableController';
import { useProfileFeaturePanelStyles } from './ProfilesFeaturePanelCSS';

interface HistogramTabComponentProps {
  amountOfBins: number;
  setAmountOfBins: (amount: number) => void;
  unifiedBinsLoading: boolean;
  unifiedCommonYRange: HistogramDomain | null;
  commonXDomain: HistogramDomain | null;
  unifiedHistograms: UnifiedHistogramWithMetadata[] | undefined;
  boxPlotData: BoxPlotData[] | undefined;
}

export function HistogramTabComponent({
  amountOfBins,
  setAmountOfBins,
  unifiedBinsLoading,
  unifiedCommonYRange,
  commonXDomain,
  unifiedHistograms,
  boxPlotData,
}: HistogramTabComponentProps): JSX.Element {
  const { classes } = useProfileFeaturePanelStyles();
  const [ref, rect] = useResizeObserver();

  // This line guards against boxPlotData being undefined or empty, so the ! is safe
  // in the plot component -- it is added only to satisfy TypeScript.
  const hasBoxPlotData = !!boxPlotData && boxPlotData.length > 0;
  const hasHistogramData = !!unifiedHistograms && unifiedHistograms.length > 0;
  const hasNoData = !hasBoxPlotData && !hasHistogramData && !unifiedBinsLoading;

  const renderNotAvailableText = (element: string) => {
    return (
      <WhyLabsText inherit className={classes.noDataText}>
        {`${element} data not available`}
      </WhyLabsText>
    );
  };
  const renderBoxPlots = () => {
    if (hasNoData) {
      return null;
    }
    return (
      <div className={classes.columnContainer}>
        <WhyLabsText inherit className={classes.sectionLabel}>
          Box plots
        </WhyLabsText>
        {hasBoxPlotData ? (
          <div ref={ref}>
            <FeaturePanelHighchartsBoxPlot
              graphHeight={rect.height}
              graphWidth={rect.width}
              boxPlotData={boxPlotData ?? []}
              domain={commonXDomain}
            />
          </div>
        ) : (
          renderNotAvailableText('Box plot')
        )}
      </div>
    );
  };

  return (
    <div className={classes.tabRoot}>
      {(hasHistogramData || unifiedBinsLoading) && (
        <FeaturePanelHistogramStack
          amountOfBins={amountOfBins}
          setAmountOfBins={setAmountOfBins}
          unifiedBinsLoading={unifiedBinsLoading}
          unifiedCommonYRange={unifiedCommonYRange}
          commonXDomain={commonXDomain}
          unifiedHistograms={unifiedHistograms}
        />
      )}
      {renderBoxPlots()}
      <div className={classes.columnContainer}>
        <WhyLabsText inherit className={classes.sectionLabel}>
          Histogram data
        </WhyLabsText>
        {hasHistogramData ? (
          <ProfilesHistogramTableController unifiedHistograms={unifiedHistograms} />
        ) : (
          renderNotAvailableText('Histogram')
        )}
      </div>
    </div>
  );
}
