import {
  DATE_RANGE_SEPARATOR,
  LEGACY_DATE_RANGE_TAG,
  NEW_GLOBAL_END_RANGE,
  NEW_GLOBAL_RANGE_PRESET,
  NEW_GLOBAL_START_RANGE,
} from 'types/navTags';

export const legacyDateRangeTranslator = (searchParams: URLSearchParams): void => {
  const rawLegacyGlobalRange = searchParams.get(LEGACY_DATE_RANGE_TAG);
  const [legacyStart, legacyEnd] = rawLegacyGlobalRange?.split(DATE_RANGE_SEPARATOR) ?? [];
  const invalidNewPickerRange =
    (!searchParams.get(NEW_GLOBAL_START_RANGE) || !searchParams.get(NEW_GLOBAL_END_RANGE)) &&
    !searchParams.get(NEW_GLOBAL_RANGE_PRESET);

  const shouldTranslateGlobalRange = !!(legacyStart && legacyEnd && invalidNewPickerRange);
  if (shouldTranslateGlobalRange) {
    searchParams.set(NEW_GLOBAL_START_RANGE, legacyStart ?? '');
    searchParams.set(NEW_GLOBAL_END_RANGE, legacyEnd ?? '');
  }
  // Always drop legacy param to avoid bugs
  searchParams.delete(LEGACY_DATE_RANGE_TAG);
};
