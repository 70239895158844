import { useEffect, useState } from 'react';
import { CircularProgress } from '@material-ui/core';
import { Colors, Spacings } from '@whylabs/observatory-lib';
import { createStyles } from '@mantine/core';
import { useResizeObserver } from '@mantine/hooks';
import { TableFeatureDataType } from 'components/controls/table/profiles-table/types';
import { useUtilityStyles } from 'styles/UtilityStyles';
import { WhyLabsDrawer, WhyLabsTabs, WhyLabsText } from 'components/design-system';
import { useDeepCompareEffect } from 'hooks/useDeepCompareEffect';
import { uniq } from 'lodash';
import { FeaturePanelHighchartsBoxPlot } from 'components/visualizations/box-plots/FeaturePanelHighchartsBoxPlot';
import { useFlags } from 'hooks/flags/useFlags';
import { PROFILE_TABS_VIEW } from 'constants/flags';
import { convertTableTypeToBoxPlotData } from 'components/visualizations/box-plots/boxPlotHelpers';
import { ColoredCategoryData } from 'components/visualizations/OverlaidColumnCharts/OverlaidColumnHighchart';
import { Link } from 'react-router-dom';
import { useNavLinkHandler } from 'hooks/usePageLinkHandler';
import { usePageTypeWithParams } from 'pages/page-types/usePageType';
import FeaturePanelHistogramStack from './FeaturePanelHistogramStack';
import FeaturePanelChart, { ChartSingleDatum } from './FeaturePanelChart';
import { useUnifiedBinsTools } from './useUnifiedBinsTools';
import { ProfilesFeatureLegacyTable } from './ProfilesFeatureLegacyTable';
import { isConvertableToNumber, readableProfileId } from './tableTools';
import { ChipTableData, ProfileDataGroup } from './tableTypes';
import { HistogramTabComponent } from './HistogramTabComponent';
import { FrequentItemsTabComponent } from './FrequentItemsTabComponent';
import { ProfilesDescriptionBar } from './ProfilesDescriptionBar';

const useContentAreaStyles = createStyles({
  root: {
    background: Colors.white,
    padding: '10px 0',
    flexGrow: 0,
    flexShrink: 0,
    zIndex: 1,
  },
  drawer: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  drawerHeader: {
    backgroundColor: Colors.darkHeader,
    color: Colors.white,
    height: '42px',
    padding: '0 14px 0 9px',
  },
  drawerTitle: {
    color: Colors.white,
    fontSize: 16,
    fontFamily: 'Asap, sans-serif',
    fontWeight: 400,
  },
  drawerLink: {
    textDecoration: 'underline',
    color: Colors.white,
    cursor: 'pointer',
    fontWeight: 600,
  },
  drawerContent: {
    display: 'box',
    height: '100vh',
    maxHeight: '100vh',
    overflow: 'hidden',
  },
  title: {
    fontFamily: 'Asap, sans-serif',
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '20px',
  },
  closeBox: {
    width: '33px',
    minWidth: '33px',
    height: '33px',
    padding: 0,
    backgroundColor: Colors.white,
    color: Colors.brandSecondary700,
  },
  chartContainer: {
    marginBottom: '30px',
  },
  boxplotsContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  boxPlotsLegacyContainer: {
    marginTop: '20px',
    height: 350,
  },
  frequentItemsContainer: {
    marginTop: '20px',
    height: 500,
  },
  chartLegend: {
    display: 'flex',
    justifyContent: 'space-evenly',
  },
  legendItem: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  legendBox: {
    height: '11px',
    width: '11px',
    marginRight: '5px',
  },
  sectionTitle: {
    fontFamily: 'Asap, sans-serif',
    fontWeight: 600,
    fontSize: '14px',
  },
  chartTitle: {
    marginBottom: '15px',
    fontFamily: 'Asap, sans-serif',
    fontSize: '14px',
    color: Colors.black,
  },
  toggleChipDataLink: {
    cursor: 'pointer',
    color: Colors.linkColor,
    fontSize: '14px',
    fontFamily: 'Asap, sans-serif',
    textDecoration: 'underline',
  },
  loader: {
    opacity: 0,
    transition: 'opacity 400ms 200ms',
    marginLeft: 8,
  },
  loaderOn: {
    transition: 'opacity 400ms',
    opacity: 1,
  },
  titleWrap: {
    display: 'flex',
    justifyContent: 'center',
  },
  tabsList: {
    paddingLeft: Spacings.pageLeftPadding,
    width: '100%',
  },
  tabsPanel: {
    height: '100vh',
    maxHeight: '100vh',
    overflow: 'auto',
    paddingTop: 0,
    paddingRight: 0,
  },
});

interface ProfilesFeaturePanelProps {
  visible: boolean;
  content: TableFeatureDataType[];
  onCloseSidePanel: () => void;
}

interface IChartData {
  [key: string]: ChartSingleDatum;
}

interface ILoader {
  chart: boolean;
  chipTable: boolean;
  updatedChipTable: boolean;
}

export default function ProfilesFeaturePanel({
  visible,
  onCloseSidePanel,
  content,
}: ProfilesFeaturePanelProps): JSX.Element | null {
  const { classes: styles, cx } = useContentAreaStyles();
  const { classes: utilityStyles } = useUtilityStyles();

  const {
    amountOfBins,
    setAmountOfBins,
    setData,
    unifiedBinsLoading,
    unifiedCommonYRange,
    commonXDomain,
    unifiedHistograms,
  } = useUnifiedBinsTools();

  const { getNavUrl } = useNavLinkHandler();
  useDeepCompareEffect(() => {
    setData(content);
  }, [content]);

  const { modelId, segment } = usePageTypeWithParams();
  const featureName = (content?.length && content[0] && content[0]['feature-name']) || '';
  const featureNavUrl = getNavUrl({ page: 'columns', featureName, modelId, segmentTags: segment });

  const [loading, setLoading] = useState<ILoader>({
    chart: false,
    chipTable: false,
    updatedChipTable: false,
  });
  const [discreteChartData, setDiscreteChartData] = useState<ChartSingleDatum[]>([]);
  const [modernDiscreteChartData, setModernDiscreteChartData] = useState<ColoredCategoryData[]>([]);
  const [categoryList, setCategoryList] = useState<string[]>([]);

  const [chipTableData, setChipTableData] = useState<ChipTableData>({
    columns: [],
    rows: {},
  });

  const [chipTableType, setChipTableType] = useState<'Frequent' | 'Histogram'>('Frequent');
  const [hasBoxPlotData, setHasBoxPlotData] = useState(false);
  const [ref, rect] = useResizeObserver();
  const { flags } = useFlags();

  useEffect(() => {
    const counts = content.reduce((arr: number[][], item) => {
      if (item && item.numberSummary?.quantiles && item.numberSummary.quantiles.counts.length > 0)
        arr.push(item.numberSummary.quantiles.counts);
      return arr;
    }, []);

    setHasBoxPlotData(counts.length > 0);
  }, [content]);

  function formatDecimalNumbers(toFormat: number): number {
    const isFloat = Number(toFormat) === toFormat && toFormat % 1 !== 0;

    if (isFloat) return parseFloat(toFormat.toFixed(3));

    return toFormat;
  }

  /**
   * Used for generating chartData.
   */
  useEffect(() => {
    const generateChartData = () => {
      const tempDiscreteChartData: IChartData = {};

      const tempHighchartsColumnData: ColoredCategoryData[] = [];
      const duplicatedCategories: string[] = [];
      content.forEach((_, profileIndex: number) => {
        const featureData = content[profileIndex]; // featureData for each profile
        if (!featureData) return;

        // For the new highcharts data types
        const name = `Profile ${profileIndex + 1}`;
        const color = Colors.profilesColorPool[profileIndex % Colors.profilesColorPool.length]; // shouldn't be needed, but just in case
        const data = featureData.featurePanelData.frequentItems.reduce((acc, item) => {
          if (item.value) {
            acc.set(item.value, item.estimate ?? null);
          }
          return acc;
        }, new Map<string, number | null>());
        tempHighchartsColumnData.push({ name, color, data });
        duplicatedCategories.push(...Array.from(data.keys()));
        // End new highcharts data type logic

        // Structures frequent items for discrete profile
        featureData.featurePanelData.frequentItems.forEach((item) => {
          if (item.value) {
            if (typeof tempDiscreteChartData[item.value] === 'undefined') {
              tempDiscreteChartData[item.value] = {
                name: isConvertableToNumber(item.value) ? formatDecimalNumbers(parseFloat(item.value)) : item.value,
              };
            }

            tempDiscreteChartData[item.value] = {
              ...tempDiscreteChartData[item.value],
              [`profile-${profileIndex + 1}`]: item.estimate || '',
            };
          }
        });
      });
      const tempHighchartsCategories = uniq(duplicatedCategories); // deduplicate categories
      setCategoryList(tempHighchartsCategories);
      setModernDiscreteChartData(tempHighchartsColumnData);

      const tempDiscreteChartDataVal: ChartSingleDatum[] = Object.values(tempDiscreteChartData);

      setDiscreteChartData(tempDiscreteChartDataVal);
      setLoading((prevState) => ({ ...prevState, chart: false }));
    };
    setLoading((prevState) => ({ ...prevState, chart: true }));
    setTimeout(generateChartData, 0);
    // We are just referencing the `content`, and we do not manipulate with its data
    // because of that we do not want this hook to depend on it
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content, setLoading]);

  /**
   * Used for creating chipTableData
   */
  useEffect(() => {
    if (!content) return;

    setLoading((prevState) => ({ ...prevState, chipTable: true }));
    setTimeout(() => {
      if (chipTableType === 'Frequent') {
        const tempChipTableData: ChipTableData = {
          columns: [],
          rows: {},
        };

        content.forEach((profile, i) => {
          if (!profile) return;
          tempChipTableData.columns.push(`Profile ${i + 1}`);

          profile.frequentItemsRaw.forEach((item) => {
            if (!item.value?.toString()) return;

            tempChipTableData.rows[item.value] = { value: item.value };
          });
        });

        content.forEach((profile, i) => {
          if (!profile) return;

          Object.keys(tempChipTableData.rows).forEach((key) => {
            const item = profile.frequentItemsRaw.find((entry) => entry.value === key);
            if (item && item.value) tempChipTableData.rows[key][`profile-${i + 1}`] = item.estimate as number;
            else tempChipTableData.rows[key][`profile-${i + 1}`] = '-';
          });
        });

        setChipTableData(tempChipTableData);
      } else if (chipTableType === 'Histogram') {
        const tempChipTableData: ChipTableData = {
          columns: [],
          rows: {},
        };
        unifiedHistograms?.forEach((histogram) => {
          if (!histogram?.data) return;
          tempChipTableData.columns.push(`Profile ${histogram.profileNum}`);
          histogram.data.bins.forEach((bin, i) => {
            if (i >= histogram.data!.counts.length) return;
            const indexKey = i.toString();
            if (!(indexKey in tempChipTableData.rows)) {
              tempChipTableData.rows[indexKey] = { value: bin };
            }
            tempChipTableData.rows[indexKey][`profile-${histogram.profileNum}`] = histogram.data!.counts[i];
          });
        });
        setChipTableData(tempChipTableData);
      }
      setLoading((prevState) => ({ ...prevState, chipTable: false }));
    }, 0);
  }, [content, chipTableType, unifiedHistograms]);

  /**
   *  Controls chart order
   */
  function generateGraphs() {
    const showFrequentItemsChartFirst = discreteChartData.length > 0 && discreteChartData[0]['profile-1'];
    const shouldRenderHistograms =
      content.filter((_, ind) => content?.[ind]?.['inferred-discretion']?.toLowerCase() === 'non-discrete').length > 0;

    return (
      <>
        {!showFrequentItemsChartFirst && shouldRenderHistograms && (
          <FeaturePanelHistogramStack
            amountOfBins={amountOfBins}
            setAmountOfBins={setAmountOfBins}
            unifiedBinsLoading={unifiedBinsLoading}
            unifiedCommonYRange={unifiedCommonYRange}
            commonXDomain={commonXDomain}
            unifiedHistograms={unifiedHistograms}
          />
        )}
        {discreteChartData.length > 0 && (
          <div className={styles.chartContainer}>
            <WhyLabsText inherit className={styles.chartTitle}>
              <span style={{ fontWeight: 600 }}>Frequent items data</span>
            </WhyLabsText>
            <FeaturePanelChart chartData={discreteChartData} shouldToggleLegend />
          </div>
        )}
        {showFrequentItemsChartFirst && shouldRenderHistograms && (
          <FeaturePanelHistogramStack
            amountOfBins={amountOfBins}
            setAmountOfBins={setAmountOfBins}
            unifiedBinsLoading={unifiedBinsLoading}
            unifiedCommonYRange={unifiedCommonYRange}
            commonXDomain={commonXDomain}
            unifiedHistograms={unifiedHistograms}
          />
        )}
      </>
    );
  }

  if (!visible) return null;

  const title = (
    <div className={styles.titleWrap}>
      <WhyLabsText inherit className={styles.title}>
        {content?.length && content[0] && content[0]['feature-name']} Distribution analysis
      </WhyLabsText>
      <CircularProgress
        size="16px"
        className={cx(styles.loader, (loading.chart || loading.chipTable) && styles.loaderOn)}
      />
    </div>
  );

  const modernTitle = (
    <WhyLabsText className={styles.drawerTitle}>
      <>
        <span>Column:{` `}</span>
        <Link className={styles.drawerLink} to={featureNavUrl}>
          {featureName}
        </Link>
      </>
    </WhyLabsText>
  );

  const renderHighchartsBoxPlot = () => {
    const boxPlotData = convertTableTypeToBoxPlotData(content);
    return (
      <div ref={ref}>
        <FeaturePanelHighchartsBoxPlot
          graphHeight={rect.height}
          graphWidth={rect.width}
          boxPlotData={boxPlotData}
          domain={commonXDomain}
        />
      </div>
    );
  };

  const renderBoxPlots = () => {
    return (
      <div className={styles.boxplotsContainer}>
        <WhyLabsText inherit className={styles.sectionTitle}>
          Box plots
        </WhyLabsText>
        {renderHighchartsBoxPlot()}
      </div>
    );
  };

  const renderTabbedView = (): JSX.Element | null => {
    if (content.length === 0) return null;
    const featureData = content[0]; // featureData for each profile
    if (!featureData) return null;

    const isDefaultDiscrete = featureData['inferred-discretion'].toLowerCase() === 'discrete';
    const descriptionBarData: ProfileDataGroup[] = content.map((profile, index) => {
      return {
        name: `Profile ${index + 1}`,
        description: readableProfileId(profile?.profileId ?? ''),
        color: profile?.profileColor ?? Colors.profilesColorPool[index % Colors.profilesColorPool.length],
      };
    });
    return (
      <>
        <ProfilesDescriptionBar profiles={descriptionBarData} />
        <WhyLabsTabs
          classNames={{
            tabsList: styles.tabsList,
            tabsPanel: styles.tabsPanel,
          }}
          defaultSelected={isDefaultDiscrete ? 'Frequent items' : 'Histogram'}
          tabs={[
            {
              label: 'Histogram',
              children: (
                <HistogramTabComponent
                  amountOfBins={amountOfBins}
                  setAmountOfBins={setAmountOfBins}
                  commonXDomain={commonXDomain}
                  unifiedCommonYRange={unifiedCommonYRange}
                  unifiedBinsLoading={unifiedBinsLoading}
                  unifiedHistograms={unifiedHistograms}
                  boxPlotData={convertTableTypeToBoxPlotData(content)}
                />
              ),
            },
            {
              label: 'Frequent items',
              children: (
                <FrequentItemsTabComponent
                  categoryList={categoryList}
                  categoryCountData={modernDiscreteChartData}
                  loading={loading.chart}
                />
              ),
            },
          ]}
        />
      </>
    );
  };

  const renderLegacyView = () => {
    return (
      <div className={styles.root}>
        {generateGraphs()}
        {hasBoxPlotData && renderBoxPlots()}
        <div className={styles.frequentItemsContainer}>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
            <WhyLabsText inherit className={styles.sectionTitle}>
              {chipTableType === 'Frequent' ? 'Frequent items' : 'Histogram data'}
            </WhyLabsText>
            <button
              className={utilityStyles.invisibleButton}
              type="button"
              onClick={() => setChipTableType(chipTableType === 'Frequent' ? 'Histogram' : 'Frequent')}
            >
              <WhyLabsText inherit className={styles.toggleChipDataLink}>
                {chipTableType === 'Frequent' ? 'Show Histogram data' : 'Show Frequent items'}
              </WhyLabsText>
            </button>
          </div>
          <ProfilesFeatureLegacyTable chipTableData={chipTableData} />
        </div>
      </div>
    );
  };

  const renderInTabs = !!flags[PROFILE_TABS_VIEW];
  const drawerWidth = flags[PROFILE_TABS_VIEW] ? 780 : 500;

  return (
    <WhyLabsDrawer
      isOpen
      padding={renderInTabs ? 0 : undefined}
      classNames={{ header: renderInTabs ? styles.drawerHeader : '', content: styles.drawerContent }}
      title={renderInTabs ? modernTitle : title}
      onClose={onCloseSidePanel}
      size={drawerWidth}
      lockScroll={renderInTabs}
      withOverlay={false}
      minWidth={500}
    >
      {renderInTabs ? renderTabbedView() : renderLegacyView()}
    </WhyLabsDrawer>
  );
}
