import { createStyles } from '@mantine/core';
import {
  Chip,
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  Tooltip,
  withStyles,
} from '@material-ui/core';
import { Colors } from '@whylabs/observatory-lib';
import CustomTooltip from './CustomTooltip';
import { formatDataForTooltip, generateChipValue } from './tableTools';
import { ChipTableData } from './tableTypes';

const useContentAreaStyles = createStyles({
  chipStyle: {
    fontWeight: 600,
    marginRight: '5px',
    marginBottom: '5px',
    '& span': {
      padding: '5px 15px',
      fontFamily: 'Inconsolata',
    },
    '&:hover': {
      backgroundColor: Colors.brandSecondary100,
    },
  },
  chipContainer: {
    position: 'relative',
  },
  chipTooltipContainer: {
    position: 'absolute',
  },
  tableContainer: {},
  tableHeaderCell: {
    fontSize: '13px',
    fontFamily: 'Inconsolata',
    fontWeight: 600,
  },
  tableBodyCell: {
    fontSize: '13px',
    fontFamily: 'Inconsolata',
  },
  tableRow: {
    '&:hover': {
      backgroundColor: Colors.brandSecondary100,
    },
  },
  tableCell: {
    borderBottom: 'none',
  },
  titleWrap: {
    display: 'flex',
    justifyContent: 'center',
  },
});

interface ProfilesFeatureLegacyTableProps {
  chipTableData: ChipTableData;
}

export function ProfilesFeatureLegacyTable({ chipTableData }: ProfilesFeatureLegacyTableProps): JSX.Element {
  const { classes, cx } = useContentAreaStyles();

  const UnstyledTooltip = withStyles(() => ({
    tooltip: {
      backgroundColor: 'unset',
      padding: 'unset',
      fontSize: '14px',
      fontFamily: 'Inconsolata',
    },
  }))(Tooltip);
  return (
    <TableContainer className={classes.tableContainer}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell
              className={cx(classes.tableHeaderCell, classes.tableCell)}
              style={{ borderTop: `2px solid ${Colors.brandSecondary200}` }}
            >
              Item
            </TableCell>
            {chipTableData.columns.map((column, i) => (
              <TableCell
                align="right"
                key={column}
                className={cx(classes.tableHeaderCell, classes.tableCell)}
                style={{ borderTop: `2px solid ${Colors.profilesColorPool[i]}` }}
              >
                {column}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.values(chipTableData.rows).map((row, i) => (
            // eslint-disable-next-line
            <TableRow key={i} className={classes.tableRow}>
              {Object.keys(row).map((profile, z) => {
                const isFirst = z === 0;

                if (isFirst)
                  return (
                    <TableCell className={classes.tableCell} key={`key-${profile}`}>
                      <UnstyledTooltip
                        title={
                          <CustomTooltip
                            data={formatDataForTooltip(row)}
                            label={generateChipValue(row.value.toString())}
                          />
                        }
                      >
                        <Chip
                          variant="outlined"
                          size="small"
                          label={generateChipValue(row[profile].toString())}
                          className={classes.chipStyle}
                        />
                      </UnstyledTooltip>
                    </TableCell>
                  );

                return (
                  <TableCell align="right" key={profile} className={cx(classes.tableBodyCell, classes.tableCell)}>
                    {row[profile]}
                  </TableCell>
                );
              })}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
