import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { createStyles } from '@mantine/core';

import { useFlags } from 'hooks/flags/useFlags';
import { Colors } from '@whylabs/observatory-lib';
import { WhyLabsText } from 'components/design-system';

const useFlagStyles = createStyles({
  greenText: {
    color: Colors.green,
    fontWeight: 'bolder',
  },
  orangeText: {
    color: Colors.darkOrange,
    fontWeight: 600,
  },
});

export const FlagsPageContent: React.FC = () => {
  const { flags } = useFlags();
  const { classes: styles } = useFlagStyles();
  const flagKeys = Object.keys(flags).sort();

  const renderFlagValue = (key: string) => {
    const styleClass = flags[key] ? styles.greenText : styles.orangeText;
    return <WhyLabsText inherit className={styleClass}>{`${flags[key]}`}</WhyLabsText>;
  };

  return (
    <div
      style={{
        paddingTop: '16px',
        paddingLeft: '16px',
        paddingRight: '48px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
      }}
    >
      {/* This is the table of feature flags and values */}
      <TableContainer style={{ width: '480px' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{ paddingLeft: '24px' }}>
                <WhyLabsText inherit>Flag</WhyLabsText>
              </TableCell>
              <TableCell>
                <WhyLabsText inherit>Value</WhyLabsText>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {flagKeys.map((flagKey, idx) => (
              <TableRow key={flagKey} style={idx % 2 === 1 ? { backgroundColor: '#EDEDED' } : {}}>
                <TableCell style={{ paddingLeft: '24px' }}>{flagKey}</TableCell>
                <TableCell>{renderFlagValue(flagKey)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};
