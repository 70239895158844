import { Drawer, DrawerProps, MantineNumberSize, Portal, createStyles } from '@mantine/core';
import { InvisibleButton } from 'components/buttons/InvisibleButton';
import { isNumber } from 'utils/typeGuards';
import { MouseEvent, ReactNode, useRef, useState } from 'react';

import { Colors } from '@whylabs/observatory-lib';
import { CLOSE_BUTTON_DEFAULT_PROPS } from '../button/WhyLabsCloseButton';

export const DRAWER_HEADER_HEIGHT = 44;
const MIN_DRAWER_WIDTH = 250;
type StyleProps = Pick<WhyLabsDrawerProps, 'withOverlay' | 'isOpen' | 'position' | 'size'> & {
  disablePointerEvents: boolean;
  scrollWidth: number;
  isDragging?: boolean;
};
const RESIZE_LINE_WIDTH = 4;
const useStyles = createStyles(
  (
    _,
    { withOverlay, isOpen, position = 'right', size, disablePointerEvents, scrollWidth, isDragging }: StyleProps,
  ) => ({
    root: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100vw',
      height: '100vh',
      display: isOpen ? 'block' : 'none',
      pointerEvents: disablePointerEvents ? 'none' : 'unset',
      cursor: isDragging ? 'col-resize' : 'unset',
    },
    wrapper: {
      width: '100%',
      height: '100%',
      position: 'relative',
    },
    resizerLine: {
      width: RESIZE_LINE_WIDTH,
      minWidth: RESIZE_LINE_WIDTH,
      height: '100%',
      cursor: 'col-resize',
      transition: 'all 100ms ease-out 50ms',
      outline: 'none',
      '&:hover': {
        transition: 'all 100ms ease-in 300ms',
        backgroundColor: `${Colors.blue} !important`,
      },
      position: 'absolute',
      [position]: `calc(${isNumber(size) ? `${size}px` : size} - ${RESIZE_LINE_WIDTH}px + ${scrollWidth}px)`,
      zIndex: 99999999,
      pointerEvents: 'all',
    },
    activeResize: {
      borderLeft: `1px solid ${Colors.transparent}`,
      transition: 'unset',
      backgroundColor: `${Colors.blue} !important`,
    },
    content: {
      boxShadow: withOverlay ? 'inherit' : '-10px 4px 20px 0px rgba(0, 0, 0, 0.25)',
    },
    darkHeader: {
      backgroundColor: Colors.darkHeader,
      borderLeft: `.5px solid ${Colors.drawerBorder}`,
      color: Colors.white,
      height: DRAWER_HEADER_HEIGHT,
    },
    handlePointerEvents: {
      pointerEvents: isDragging ? 'none' : undefined,
    },
  }),
);

type WhyLabsDrawerProps = Pick<
  DrawerProps,
  'lockScroll' | 'onClose' | 'padding' | 'size' | 'title' | 'withCloseButton' | 'withOverlay'
> & {
  children: ReactNode;
  classNames?: {
    close?: string;
    body?: string;
    content?: string;
    header?: string;
  };
  closeButtonLabel?: string;
  darkHeader?: boolean;
  isOpen: boolean;
  position?: 'right' | 'left';
  resizable?: boolean;
  // minWidth when the drawer is resizable
  minWidth?: number;
  // maxWidth when the drawer is resizable
  maxWidth?: number;
};

interface ResizerState {
  isDragging: boolean;
  width?: MantineNumberSize;
  lastClientX?: number;
}

export const WhyLabsDrawer = ({
  classNames,
  children,
  closeButtonLabel = 'Close Drawer',
  darkHeader,
  isOpen,
  position = 'right',
  onClose,
  resizable = true,
  minWidth = MIN_DRAWER_WIDTH,
  maxWidth,
  withOverlay = true,
  ...rest
}: WhyLabsDrawerProps): JSX.Element => {
  const [drawerResizer, setThreeResizer] = useState<ResizerState>({
    isDragging: false,
    width: rest.size,
  });

  const disablePointerEvents = !withOverlay && !drawerResizer.isDragging;
  const scrollWidth = window.innerWidth - document.body.clientWidth;
  const { classes, cx } = useStyles({
    isOpen,
    position,
    ...rest,
    size: drawerResizer.width,
    disablePointerEvents,
    scrollWidth: drawerResizer.lastClientX === undefined ? 0 : scrollWidth,
    isDragging: drawerResizer.isDragging,
  });
  const container = useRef<HTMLDivElement | null>(null);
  const usedMaxWidth = maxWidth ?? window.innerWidth * 0.9;

  const handleResizeMouseDown = (ev?: MouseEvent<HTMLButtonElement>) => {
    if (!ev || !container.current) return;
    ev.stopPropagation();
    setThreeResizer((prev) => {
      return { ...prev, isDragging: true, lastClientX: ev?.clientX };
    });
  };

  const handleResizeMouseUp = (ev?: MouseEvent<HTMLDivElement>) => {
    setThreeResizer((prev) => {
      if (prev.isDragging) return { ...prev, isDragging: false, lastClientX: ev?.clientX };
      return prev;
    });
  };

  const handleResizing = (ev?: MouseEvent<HTMLDivElement>) => {
    if (!drawerResizer.isDragging) return;
    setThreeResizer((prev) => {
      if (!container.current || !ev?.clientX) return prev;
      const clientX = ev.clientX + 4;
      const newWidth = position === 'left' ? clientX : document.body.clientWidth - clientX;
      if (newWidth < minWidth) return { ...prev, width: minWidth };
      if (newWidth >= usedMaxWidth) return { ...prev, width: usedMaxWidth };
      return { ...prev, lastClientX: ev?.clientX, width: newWidth };
    });
  };

  const handleOnClose = () => {
    setThreeResizer((prev) => ({ ...prev, isDragging: false }));
    onClose();
  };

  return (
    <Portal className={classes.root}>
      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
      <div
        className={classes.wrapper}
        ref={container}
        onMouseMove={handleResizing}
        onMouseUp={handleResizeMouseUp}
        onMouseLeave={handleResizeMouseUp}
      >
        {resizable && (
          <InvisibleButton
            className={cx(classes.resizerLine, { [classes.activeResize]: drawerResizer.isDragging })}
            onMouseDown={handleResizeMouseDown}
          />
        )}
        <Drawer
          classNames={{
            ...classNames,
            header: cx(
              {
                [classes.darkHeader]: darkHeader,
              },
              classes.handlePointerEvents,
              classNames?.header,
            ),
            content: cx(classes.content, classNames?.content),
            body: cx(classes.handlePointerEvents, classNames?.body),
          }}
          closeButtonProps={{
            'aria-label': closeButtonLabel,
            title: closeButtonLabel,
            ...CLOSE_BUTTON_DEFAULT_PROPS,
          }}
          data-testid="WhyLabsDrawer"
          opened={isOpen}
          position={position}
          withinPortal={false}
          onClose={handleOnClose}
          {...rest}
          size={drawerResizer.width}
          withOverlay={withOverlay}
        >
          {children}
        </Drawer>
      </div>
    </Portal>
  );
};
