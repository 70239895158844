import { SortDirection } from 'generated/graphql';
import { SortDirectionType } from 'hooks/useSort/types';

/**
 * Desc -> Asc -> undefined -> Desc
 */
export function getNextSortDirectionState(sortDirection: SortDirectionType): SortDirectionType {
  switch (sortDirection) {
    case undefined:
      return SortDirection.Desc;
    case SortDirection.Desc:
      return SortDirection.Asc;
    case SortDirection.Asc:
    default:
      return undefined;
  }
}
