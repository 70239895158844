import { createStyles } from '@mantine/core';
import { WhyLabsLoadingOverlay, WhyLabsTabs } from 'components/design-system';
import { usePageTypeWithParams } from 'pages/page-types/usePageType';
import { ModelRoutes, useNavLinkHandler } from 'hooks/usePageLinkHandler';
import { ExplainabilityTab } from 'pages/explainability/ExplainabilityTab';
import { PageType } from 'pages/page-types/pageType';
import { INDIVIDUAL_PROFILES } from 'constants/flags';
import { useFlags } from 'hooks/flags/useFlags';

import { CONSTRAINTS_TAB, EXPLAINABILITY_TAB, PERFORMANCE_TAB, SEGMENT_ANALYSIS_TAB } from 'constants/analyticsIds';
import { TABS_HEIGHT } from 'ui/constants';
import { CONSTRAINTS_SUPPORTED_RESOURCE_TYPE } from '../route-bases/resolvers/utils';
import ModelPerformancePanel from '../model-page/model-performance-panel/ModelPerformancePanel';
import { useFetchModelType } from '../route-bases/resolvers/useFetchModelType';
import { ConstraintsWrapper } from './ConstraintsWrapper';
import { SegmentAnalysisPage } from '../segment-analysis/SegmentAnalysis';

const useStyles = createStyles(() => ({
  root: {
    height: `calc(100% - ${TABS_HEIGHT}px)`,
    width: '100%',
  },
  tabsList: {
    padding: '20px 0 0 16px',
    width: `100%`,
  },
  tabsPanel: {
    width: '100%',
    height: '100%',
    display: 'flex',
    padding: 0,
  },
}));

const mapTabToNavigationPath = new Map<string, ModelRoutes>([
  ['Constraints', 'constraints'],
  ['Performance', 'performance'],
  ['Explainability', 'explainability'],
  ['Segment Analysis', 'segment-analysis'],
]);

const mapPageTypeToTabLabel = new Map<PageType, string>([
  ['constraints', 'Constraints'],
  ['segmentConstraints', 'Constraints'],
  ['performance', 'Performance'],
  ['segmentPerformance', 'Performance'],
  ['explainability', 'Explainability'],
  ['segmentAnalysis', 'Segment Analysis'],
]);

export const ResourceDashboardsView: React.FC = () => {
  const { classes } = useStyles();
  const { pageType, modelId, segment } = usePageTypeWithParams();
  const { modelType, loading } = useFetchModelType();
  const { flags } = useFlags();
  const allowConstraints =
    modelType && CONSTRAINTS_SUPPORTED_RESOURCE_TYPE.includes(modelType) && flags[INDIVIDUAL_PROFILES];
  const defaultTab = mapPageTypeToTabLabel.get(pageType) ?? 'Performance';
  const { handleNavigation } = useNavLinkHandler();

  const navToTab = (value: string) => {
    const page = mapTabToNavigationPath.get(value);
    if (!page) return;
    handleNavigation({
      modelId,
      page,
      segmentTags: segment,
    });
  };

  if (loading !== false || !flags) return <WhyLabsLoadingOverlay visible />;

  return (
    <div className={classes.root}>
      <WhyLabsTabs
        onTabChange={navToTab}
        defaultSelected={defaultTab}
        classNames={{
          tabsList: classes.tabsList,
          tabsPanel: classes.tabsPanel,
        }}
        tabs={[
          {
            children: <ModelPerformancePanel />,
            label: 'Performance',
            id: PERFORMANCE_TAB,
          },
          {
            children: <SegmentAnalysisPage />,
            label: 'Segment Analysis',
            id: SEGMENT_ANALYSIS_TAB,
          },
          {
            children: <ExplainabilityTab />,
            label: 'Explainability',
            id: EXPLAINABILITY_TAB,
          },
          ...(allowConstraints
            ? [
                {
                  children: <ConstraintsWrapper />,
                  label: 'Constraints',
                  id: CONSTRAINTS_TAB,
                },
              ]
            : []),
        ]}
      />
    </div>
  );
};
