import { useResizeObserver } from '@mantine/hooks';
import { WhyLabsText } from 'components/design-system';
import {
  ColoredCategoryData,
  OverlaidColumnHighchart,
} from 'components/visualizations/OverlaidColumnCharts/OverlaidColumnHighchart';
import { ProfilesFrequentItemsTable } from './ProfilesFrequentItemsTable';
import { FrequentItemsRow } from './tableTypes';
import { useProfileFeaturePanelStyles } from './ProfilesFeaturePanelCSS';

interface FrequentItemsTabComponentProps {
  categoryList: string[];
  categoryCountData: ColoredCategoryData[];
  loading: boolean;
}

export function FrequentItemsTabComponent({
  categoryList,
  categoryCountData,
  loading,
}: FrequentItemsTabComponentProps): JSX.Element {
  const { classes } = useProfileFeaturePanelStyles();
  const [ref, rect] = useResizeObserver();

  const profileColumns = categoryCountData.map((countData) => ({
    name: countData.name,
    color: countData.color,
  }));
  const profileCount = categoryCountData.length;
  const rows = categoryList.reduce<FrequentItemsRow[]>((acc, category) => {
    return [
      ...acc,
      {
        item: category,
        profileCounts: [
          profileCount > 0 ? categoryCountData[0].data.get(category) ?? null : null,
          profileCount > 1 ? categoryCountData[1].data.get(category) ?? null : null,
          profileCount > 2 ? categoryCountData[2].data.get(category) ?? null : null,
        ],
      },
    ];
  }, []);
  const hasCategories = categoryList.length > 0 || loading;
  return (
    <div className={classes.tabRoot}>
      <div ref={ref}>
        <WhyLabsText inherit className={classes.sectionLabel}>
          Frequent items plot
        </WhyLabsText>
        {hasCategories ? (
          <OverlaidColumnHighchart
            graphHeight={300}
            graphWidth={rect.width}
            categoryCounts={categoryCountData}
            categories={categoryList}
            graphHorizontalBuffer={0}
            graphVerticalBuffer={0}
          />
        ) : (
          <WhyLabsText inherit className={classes.noDataText}>
            Frequent items data not available
          </WhyLabsText>
        )}
      </div>
      {hasCategories && (
        <WhyLabsText inherit className={classes.sectionLabel}>
          Frequent items data
        </WhyLabsText>
      )}
      {hasCategories && <ProfilesFrequentItemsTable profileColumns={profileColumns} rows={rows} />}
    </div>
  );
}
