import { ApolloError } from '@apollo/client';
import { createStyles, Skeleton } from '@mantine/core';
import { IconAlertTriangle } from '@tabler/icons';
import { Colors, HtmlTooltip, Spacings } from '@whylabs/observatory-lib';
import { WhyLabsBadge, WhyLabsTooltip, WhyLabsButton, WhyLabsText } from 'components/design-system';
import ExternalLink from 'components/link/ExternalLink';
import LogRocket from 'logrocket';
import { useNavLinkHandler } from 'hooks/usePageLinkHandler';

const useStyles = createStyles({
  localRoot: {
    border: 'none',
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: Colors.white,
    minHeight: Spacings.tabContentHeaderHeight,
    borderLeft: `1px solid ${Colors.brandSecondary200}`,
  },
  displayNumberContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  projectCount: {
    textAlign: 'left',
  },
  innerContainer: {
    display: 'flex',
    gap: '13px',
    flexDirection: 'row',
    height: '100%',
    padding: '10px 20px',
  },
  leftColumn: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
  },
  rightColumn: {
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
    height: '100%',
    justifyContent: 'flex-end',
    marginLeft: 5,
  },
  widgetTitle: {
    fontFamily: 'Asap,Roboto,sans-serif',
    fontSize: '14px',
    fontWeight: 600,
    color: Colors.brandSecondary900,
    lineHeight: '20px',
    whiteSpace: 'nowrap',
  },
  widgetHighlightNumber: {
    fontFamily: 'Asap,Roboto,sans-serif',
    fontSize: '36px',
    lineHeight: '38px',
    minWidth: 'fit-content',
    color: Colors.brandPrimary900,
  },
});

const MODEL_COUNT_TOOLTIP = 'Total number of models';
const DATASET_COUNT_TOOLTIP = 'Total number of datasets';

interface ResourcesCountWidgetProps {
  isOverSubscriptionLimit?: boolean;
  addResource: () => void;
  loading: boolean;
  error: ApolloError | undefined;
  modelsCount: number | undefined;
  datasetsCount: number | undefined;
  userCanManageDatasets: boolean;
}

const ResourcesCountWidget: React.FC<ResourcesCountWidgetProps> = ({
  isOverSubscriptionLimit,
  addResource,
  loading,
  modelsCount,
  datasetsCount,
  error,
  userCanManageDatasets,
}) => {
  const { classes: localStyles, cx } = useStyles();
  const { handleNavigation } = useNavLinkHandler();

  if (error) {
    LogRocket.error(`ModelCountWidget component error: ${error}`);
  }
  return (
    <div className={localStyles.localRoot}>
      <div className={localStyles.innerContainer}>
        <div className={localStyles.leftColumn}>
          <WhyLabsText className={localStyles.widgetTitle}>
            Datasets
            <HtmlTooltip tooltipContent={DATASET_COUNT_TOOLTIP} />
          </WhyLabsText>
          <div className={localStyles.displayNumberContainer}>
            {loading ? (
              <Skeleton variant="text" width={50} height={38} />
            ) : (
              <div className={cx(localStyles.projectCount)}>
                <WhyLabsText className={localStyles.widgetHighlightNumber}>
                  {renderResourceCount(datasetsCount)}
                </WhyLabsText>
              </div>
            )}
          </div>
        </div>
        <div className={localStyles.leftColumn}>
          <WhyLabsText className={localStyles.widgetTitle}>
            Models
            <HtmlTooltip tooltipContent={MODEL_COUNT_TOOLTIP} />
          </WhyLabsText>
          <div className={localStyles.displayNumberContainer}>
            {loading ? (
              <Skeleton variant="text" width={50} height={38} />
            ) : (
              <div className={cx(localStyles.projectCount)}>
                <WhyLabsText className={localStyles.widgetHighlightNumber}>
                  {renderResourceCount(modelsCount)}
                </WhyLabsText>
              </div>
            )}
          </div>
        </div>
        {loading ? (
          <div>
            <Skeleton variant="text" width={120} height={20} mt={4} />
            <Skeleton variant="text" width={120} height={36} mt={10} />
          </div>
        ) : (
          renderButtons()
        )}
      </div>
    </div>
  );

  function renderResourceCount(count?: number) {
    if (!error) {
      return count ?? 0;
    }
    return '-';
  }

  function renderButtons() {
    if (!userCanManageDatasets || loading || error) {
      return null;
    }

    const buttonText = isOverSubscriptionLimit ? 'Upgrade plan' : 'Set up resource';
    const clickAction = isOverSubscriptionLimit ? () => handleNavigation({ page: 'upgrade' }) : addResource;
    return (
      <div className={localStyles.rightColumn}>
        {renderOverLimitBadge()}
        <WhyLabsButton
          variant="outline"
          width="full"
          size="xs"
          color="gray"
          onClick={clickAction}
          id="upgrade-plan-button"
        >
          {buttonText}
        </WhyLabsButton>
      </div>
    );
  }

  function renderOverLimitBadge() {
    if (!isOverSubscriptionLimit) {
      return null;
    }

    return (
      <WhyLabsTooltip label="Upgrade plan for more projects" position="top">
        <ExternalLink to="pricing" style={{ textDecoration: 'none', width: '100%' }}>
          <WhyLabsBadge
            color="pink"
            fullWidth
            leftSection={
              <IconAlertTriangle
                style={{
                  height: 15,
                  width: 'fit-content',
                }}
              />
            }
          >
            OVER LIMITS
          </WhyLabsBadge>
        </ExternalLink>
      </WhyLabsTooltip>
    );
  }
};

export default ResourcesCountWidget;
